import React from "react";
import Header from "../components/header";

import {Helmet} from "react-helmet";
import "../styles/index.css";
import {StaticImage} from "gatsby-plugin-image";

function Index() {
    return (
        <main>
            <Helmet>
                <title>Visual Energy Training Exercise</title>
            </Helmet>

            <Header/>

            <section class="bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1">
                            <h1>Visual Energy Training Exercise</h1>
                            <p class="lead">
                                User research, design, full-stack dev for NSF-funded research. The project began in
                                February of 2020 and
                                is ongoing.
                            </p>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2">

                            <StaticImage
                                src="../images/vete-prototype.png"
                                alt="Journey mapping course on an iPad"
                                placeholder="blurred"
                                className="img-fluid"
                            />

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">

                            <div className="col-sm-12">
                                <h2>Problem</h2>
                                <p className="">
                                    Many people are not able to accurately estimate the amount of energy consumed by
                                    everyday household
                                    appliances.
                                    Based on prior research by the Attari Lab at Indiana University, this project is
                                    testing a new
                                    visual method for improving the average person's energy judgement.
                                </p>

                            </div>
                            <div className="col-sm-12">
                                <h2>Solution</h2>

                                <p>
                                    The project has two key goals:
                                </p>
                                <ol>
                                    <li>
                                        Engage users and improve their understanding of energy usage
                                    </li>
                                    <li>
                                        Provide specific data to answer our research questions
                                    </li>
                                </ol>
                            </div>
                            <div className="col-sm-12">
                                <h2>My Role</h2>
                                <p className="">
                                    My primary role on this team is the user research, design and development the
                                    full-stack VETE
                                    application.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <h2>Early Prototypes</h2>

                            <div id="prototypeCarousel" className="carousel slide" data-ride="carousel"
                                 data-interval="false">
                                <ol className="carousel-indicators my-4">
                                    <li data-target="#prototypeCarousel" data-slide-to="0" className="active"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="1"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="2"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="3"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="4"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="5"></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <StaticImage
                                            src="../images/vete-how-it-works.png"
                                            alt="..."
                                            placeholder="blurred"
                                            className="img-fluid"
                                        />
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of the introduction screen</p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <StaticImage
                                            src="../images/vete-intro-video.png"
                                            alt="..."
                                            placeholder="blurred"
                                            className="img-fluid"
                                        />
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of the introduction video</p>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <a href="#admin-ux-drawing">
                                            <StaticImage
                                                src="../images/vete-test-three-appliances.png"
                                                alt="..."
                                                placeholder="blurred"
                                                className="img-fluid"
                                            />
                                        </a>
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of system a pre-test concept</p>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <a href="#admin-ux-drawing">
                                            <StaticImage
                                                src="../images/vete-mega-test.png"
                                                alt="..."
                                                placeholder="blurred"
                                                className="img-fluid"
                                            />
                                        </a>
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of a different pre-test concept</p>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <a href="#admin-ux-drawing">
                                            <StaticImage
                                                src="../images/vete-mega-test-results.png"
                                                alt="..."
                                                placeholder="blurred"
                                                className="img-fluid"
                                            />
                                            {/*<img src="/static/vete-mega-test-results.png" className="d-block w-100" alt="..."></img>*/}
                                        </a>
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of system for showing the user's score</p>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <a href="#admin-ux-drawing">
                                            <StaticImage
                                                src="../images/vete-retest.png"
                                                alt="Journey mapping course on an iPad"
                                                placeholder="blurred"
                                                className="img-fluid"
                                            />
                                        </a>
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype allowing user to re-take the test</p>
                                        </div>
                                    </div>

                                </div>

                                {/* carcousel controls */}
                                <a className="carousel-control-prev" href="#prototypeCarousel" role="button"
                                   data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#prototypeCarousel" role="button"
                                   data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>

                        </div>

                    </div>
                </div>
            </section>

        </main>
    );
}

export default Index;
